export class MessageConstant {
  static msgRequiredLogin: string = "로그인 후 이용 가능합니다.";

  //omntrition
  static msgDeleteConsumer:string="삭제 하시겠습니까?";

  static msgInvalidJumin: string = "유효하지 않은 주민등록번호.";
  static msgReqAgreeCondition: string = "이용약관에 동의해주십시오.";
  static msgConfirmSaveJumin: string = "주민등록번호 저장 하시겠습니까?";

  static msgLoginedFailed: string = "아이디 또는비밀번호가 올바르지 않습니다.";

  static msgConsumer:string="소비자명을 선택해 주세요.";
  static msgConsumerName:string="소비자명을 선택해 주세요.";
  static msgConsumerEmail:string="정확한 이메일을 입력해주세요.";
  static msgConsumerMobile:string="정확한 전화번호를 입력해주세요.";
  static msgListProduct:string="주문 제품을 선택하세요.";
  
  static msgConfirmCancelOrder = "모든 주문을 취소하시겠습니까?";
  static msgCartIsEmpty = "장바구니에 담긴 상품이 없습니다.";
  static msgConfirmRemoveCartItem = "이 제품을 삭제하시겠습니까?";

  /* checkout */
  static msgBankRegDateRequired = "등록 날짜를 입력하세요.";
  static msgBankCardHolderRequired = "적금자명을 입력하세요.";

  static msgConditionRequired = "지불하기 전에 모든 조건에 동의하십시오.";
  static msgRNameRequired = "받는사람을 입력해주십시오.";
  static msgRMobileRequired = "핸드폰번호를 입력해주십시오.";
  static msgRAddressRequired = "받는 분의 주소를 입력해주세요.";

  static msgCardNoRequired = "카드 번호를 입력해 주시기 바랍니다.";
  static msgCardHolderRequired = "카드 소유주를 입력해 주시기 바랍니다.";
  static msgCardYYMMRequired = "유효기간과 할부기간을 선택해 주시기 바랍니다.";
  static msgCardYYMMInvalid = "유효기간 : XX월 XX년 입력해 주세요.";
  static msgCardBirthdayRequired = "생년월일을 입력하세요.";
  static msgCardBusinessNoRequired = "사업자번호 입력하세요.";
  static msgCardBirthdayInvalid = "당신의 생일은 유효하지 않습니다.";
  static msgCardPasswordRequired = "카드 비밀번호를 입력해 주시기 바랍니다.";
  static msgCardPasswordInvalid = "카드 비밀번호가 유효하지 않습니다.";

  static msgMaccoDedNo = "공제번호 발급이 성공되었습니다. 공제번호: ";
  static msgMaccoDedNOMissing = "[기 신고건]누락된 공제번호가 등록되었습니다. NO: ";
  static msgMaccoDedNOErr = "공제번호 발급이 실패되었습니다. 에러코드: ";
  static msgMaccoNoOrdData = "해당 주문번호의 데이터가 없습니다.";
  static msgMaccoNetworkErr = "[직접판매공제조합]과 [공제번호 발급/취소]를 위한 네트워크 통신이 되지 않습니다. 전산담당자에게 공제번호 발급/취소가 되지'않는다]라는 사실을 알려주시기 바랍니다.";
  /* checkout */

  /* start regist member */
  static msgEnterRecommender: string = '추천인을 입력하시기 바랍니다.';
  static msgEnterSponsor: string = '후원인 입력하시기 바랍니다.';
  static msgEmailInvalidation: string = '이메일이 정확하지 않습니다.';
  static msgWrongEmail: string = '잘못된 이메일';
  static msgValidRegisEmail: string = '유효한 등록 이메일';
  static msgRegisteredEmail: string = '이메일이 등록되었습니다';
  static msgInvalidPhoneNumber: string = '핸드폰 번호가 정확하지 않습니다.';
  static msgValidPhoneNumber: string = '유효한 등록 전화번호';
  static msgRegistedPhoneNumber: string = '등록된 전화번호입니다. 다른 전화번호를 등록해 주세요.';
  static msgCheckAccount: string = '먼저 계정을 확인';
  static msgNoMemInfor: string = '고객정보가 없습니다.';
  static msgAgreeTerm: string = '이용약관에 동의해주십시오.';
  static msgNoChecked: string = '체크사항에 아니요를 선택시 회원가입을 진행하실 수 없습니다.';
  static msgSponsor : string = '후원인명 입력해주세요';
  static msgSponsorRecommendedPerson : string = '추천인명 입력해주세요';
  static msgMember : string = '회원명 입력해주세요';
  static topResult : string = '최상위 검색 결과입니다.';
  /* end regist member */

  /* start Celljeune korea */
  /* persional infor */
  /* change info */
  static msgConfirmChangeInfo: string = '정보를 저장 하시겠습니까?';
  static msgAddrValid: string = '받는 분의 주소를 입력해주세요';
  static msgRegisteredPhone: string = '등록된 전화번호';
  static msgValidPhone: string = '유효하지 않은 번호입니다.';
  static msgCopyMemberRegisURL: string = '회원등록 URL 복사되었습니다.';
  /* change password */
  static msgCurrentIncorrect: string = '현재 암호가 잘못되었습니다.';
  static msgSaveInformation: string = '정보를 저장 하시겠습니까?';
  /* change residend id */
  static msgNameAuthenSuccess: string = '실명인증 성공.';
  /* end Celljeune korea */
  static msgQuantityInvalid: string = '수량이 유효하지 않습니다.';
  static msgPriceInvalid: string = '가격이 잘못되었습니다.';
  
  /* start sale-assitance */
  static msgEnterMemberName: string = '회원명 입력해주세요';
  /* end sale-assitance */
  
  /* start login */
  static msgConsumerNotFound: string = '소비자정보를 찾을수 없습니다.';
  static msgResetedPass: string = '패스워드가 생년월일로 초기화 되었습니다.';
  static msgMemberNotFound: string = '존재하지 않는 회원입니다.';
  static msgAlreadyChanged: string = '이미 변경이 완료되었습니다.';
  /* end login */

  /* start dash board */
  static msgDashBoad1 : string = '가장 최근 데이터입니다.';
  static msgDashBoad2 : string = '더 이상  데이터가 없습니다.';
  /* end dash board */

  static msgWarningCalendar: string = '가장 최근 데이터입니다.';

  /* start qna*/
  static msgQna1 : string = '삭제 하시겠습니까?';
   /* end qna*/

  /* reg ado */
  static msgSaleQtyMinInvalid: string = "[최소 주문수량: {0}] 최소 주문수량보다 작게 설정할 수 없습니다.";
  static msgSaleQtyMaxInvalid: string = "[최대 주문수량: {0}] 해당 상품의 최대 주문수량을 초과할 수 없습니다.";
  static msgCartEmpty: string = "상품을 선택하세요.";
  static msgUseridIsRequired: string = "회원을 선택하세요.";
  static msgRegDateRequired: string = "오토십 등록일을 입력하세요.";
  static msgAdmitDateRequired: string = "오토십일자를 선택하세요."; 
  static msgADORecipientRequired: string = "받는사람을 입력하세요."; 
  static msgADOMobileRequired: string = "핸드폰번호를 입력하세요."; 
  static msgADOPostRequired: string = "우편번호를 입력하세요."; 
  static msgADOAddrRequired: string = "상세주소를 입력하세요."; 
  static msgADOCardOwnerRequired: string = "소유주를 입력하세요."; 
  static msgADOCardNoRequired: string = "카드번호를 입력해 주시기 바랍니다."; 
  static msgADOCardExpRequired: string = "카드유효기간을 입력하세요."; 
  static msgADOCardPwRequired: string = " 카드 비밀번호 2자리를 입력하세요."; 
  static msgADOBirthdayRequired: string = "생년월일 6자리를 입력하세요."; 
  static msgADOAgreementConditionRequired: string = "이용약관에 동의해주십시오."; 
  static msgADOMinTotalPVInvalid: string = "{0}PV이상 신청 하셔야 합니다."
  static msgADOTotalOrderedInvalid: string = "기존 구매 회원만 오토쉽 주문이 가능합니다.";
  static msgConfirmCancelAdo: string = "오토십 해지하시면 90일 동안 재가입 불가합니다. <br> 오토십 주문을 해지하시겠습니까?";
  static msgRegAdoSuccess: string = "오토십번호: {0} <br> 주문이 완료되었습니다.";
  static msgWarningHaveAdo: string = "현재 진행중인 오토쉽이 있습니다.";
  static msgWarningADOTotalAdoCancelBetween90Days: string = "철회/해지 후 90일 이내로 신청이 불가합니다.";

  /* start download file*/
  static msgDownloadFileFail : string = '다운로드할 파일이 없습니다.';
  static msgDownloadFileSuccess : string = '파일 다운로드 성공.';
  /* end qna*/

  /* start member*/
  static msgBoxLv : string = '입력값이 범위를 벗어났습니다!(0 - 100)';
  /* end member*/

}
